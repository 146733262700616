import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_API_TOKEN;
export const GetCurrency = async () => {
  try {
    const resp = await axios.get(`${apiUrl}/api/Currency/get-latest-rate`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  } catch (err) {
    return err.response;
  }
};
