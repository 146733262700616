import React, { useEffect, useState } from "react";

import { GetCurrency } from "../api";
const Currency = () => {
  const [dollar, setDollar] = useState(
    localStorage.getItem("dollar") ? localStorage.getItem("dollar") : null
  );
  const [pound, setPound] = useState(
    localStorage.getItem("pound") ? localStorage.getItem("pound") : null
  );
  const [euro, setEuro] = useState(
    localStorage.getItem("euro") ? localStorage.getItem("euro") : null
  );
  const [time, setTime] = useState(
    localStorage.getItem("lastUpdate")
      ? localStorage.getItem("lastUpdate")
      : null
  );
  const updateTime = process.env.REACT_APP_UPDATE_TIME;

  const fetchCurrencyRates = async () => {
    try {
      const response = await GetCurrency();
      if (response.isRequestSuccessful) {
        localStorage.setItem("dollar", response.successResponse.usd);
        localStorage.setItem("pound", response.successResponse.pnd);
        localStorage.setItem("euro", response.successResponse.eur);
        localStorage.setItem(
          "lastUpdate",
          response.successResponse.lastUpdateTime
        );
        setDollar(response.successResponse.usd);
        setPound(response.successResponse.pnd);
        setEuro(response.successResponse.eur);
        setTime(response.successResponse.lastUpdateTime);
      }
    } catch (error) {
      console.error("Error fetching currency rates:", error);
    }
  };
  useEffect(() => {
    // Fetch initially
    fetchCurrencyRates();
    // Fetch again
    const interval = setInterval(fetchCurrencyRates, updateTime * 60 * 1000);
    // Clear interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <div className="main-div">
        <div className="upper-div">
          <span className="upper-div-content-1">
            We now accept international cash payments!
          </span>
          <br />
          <span className="upper-div-content-2">CURRENT RATE</span>
        </div>

        <div className="lower-div">
          <div className="lower-div-content">
            <span className="currency-logo">€ 1 = R {euro ? euro : "-"}</span>
            <span className="currency-logo">
              $ 1 = R {dollar ? dollar : "-"}
            </span>
            <span className="currency-logo">£ 1 = R {pound ? pound : "-"}</span>
          </div>
        </div>
        <div className="time">
          <span className="time-content">{time}</span>
          <br />
          <span className="tax-content">
            *All conversions carry a small handling fee of 7.5%
          </span>
        </div>
      </div>
    </>
  );
};

export default Currency;
